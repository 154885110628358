<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <b-row class="class align-items-center mb-3">
                <b-col sm="2" cols="4" offset="4" offset-sm="0">
                    <lottie class="animated-fast fadeInDown" :options="iconCashAnimation"/>
                </b-col>
                <b-col sm="5" class="order-sm-first">
                    <wallet-balance-cannabis class="animated-fast fadeInLeft" :balance="balances.cbd"></wallet-balance-cannabis>
                </b-col>
                <b-col sm="5">
                    <wallet-balance-fiat class="animated-fast fadeInRight" :balance="balances.fiat"></wallet-balance-fiat>
                </b-col>
            </b-row>

            <b-row v-if="balances.fiat.currency.label" class="animated-fast fadeInLeft">
                <b-col md="9">
                    <div class="card card-default">
                        <div class="card-header">
                            <i class="card-title-icon fad fa-history"></i> {{$t('wallet.component.balance_history.title')}}
                        </div>
                        <div class="card-body overflow-x-scrollable">
                            <line-chart class="w-100" style="height:200px;min-width: 700px" :chart-data="chart.profit.data" :options="chart.profit.options"></line-chart>
                        </div>
                    </div>
                </b-col>
                <b-col md="3">
                    <div class="card card-default" v-if="chart.partition.data">
                        <div class="card-header">
                            <i class="card-title-icon fad fa-book"></i> {{$t('wallet.component.balance_history_division.title')}}
                        </div>
                        <div class="card-body">
                            <pie-chart class="w-100" style="height:200px" :chart-data="chart.partition.data" :options="chart.partition.options"></pie-chart>
                        </div>
                    </div>
                </b-col>
            </b-row>

            <div class="card card-default animated-fast fadeInRight" v-if="balances.stats.commission_summary !== undefined">
                <div class="card-header">
                    <span class="mb-2 d-inline-block">
                           <i class="card-title-icon fad fa-user-friends"></i> {{$t('wallet.component.commission_summary.title')}}
                    </span>

                    <nav class="float-right mr-3">
                        <b-btn variant="link" class="btn-sm ml-3 mb-1 text-secondary p-0" @click="changeCommissionChartData('monthly')"><i class="fad fa-calendar-alt mr-1"></i> Monthly</b-btn>
                        <b-btn variant="link" class="btn-sm ml-3 mb-1 text-secondary p-0" @click="changeCommissionChartData('weekly')"><i class="fad fa-calendar-week mr-1"></i> Weekly</b-btn>
                        <b-btn variant="link" class="btn-sm ml-3 mb-1 text-secondary p-0" @click="changeCommissionChartData('daily')"><i class="fad fa-calendar-day mr-1"></i> Daily</b-btn>
                    </nav>
                </div>
                <div class="card-body overflow-x-scrollable">
                    <bar-chart class="w-100" style="height:200px;min-width: 700px" :chart-data="chart.commission_summary.data" :options="chart.commission_summary.options"></bar-chart>
                </div>
            </div>

            <wallet-transaction-browser class="animated-fast fadeInUp"></wallet-transaction-browser>

            <wallet-order-browser class="animated-fast fadeInUp"></wallet-order-browser>

        </div>
    </ContentWrapper>
</template>
<script>


    //animations
    import Lottie from 'vue-lottie';
    import * as iconCashAnimationData from '@/assets/animations/icon-cash.json';

    //charts
    import LineChart from '@/components/Charts/LineChart';
    import PieChart from '@/components/Charts/PieChart';
    import BarChart from '@/components/Charts/BarChart';

    //panels
    import WalletBalanceFiat from '../components/BalanceFiat';
    import WalletBalanceCannabis from '../components/BalanceCannabis';
    import WalletTransactionBrowser from '../components/TransactionBrowser.vue';
    import WalletOrderBrowser from '../components/OrderBrowser.vue';

    export default {
        components: {
            WalletBalanceFiat,
            WalletBalanceCannabis,
            WalletTransactionBrowser,
            WalletOrderBrowser,
            LineChart,
            BarChart,
            PieChart,
            lottie: Lottie
        },
        data() {
            return {
                balances: {
                    fiat: {
                        amount: 0,
                        currency: {
                            label: '',
                            shorthand: ''
                        }
                    },
                    cbd: {
                        amount: 0,
                        currency: {
                            label: '',
                            shorthand: ''
                        }
                    },
                    stats: {

                    }
                },
                chart: {
                    profit: {
                        options: null,
                        data: null
                    },
                    partition: {
                        options: null,
                        data: null
                    },
                    commission_summary: {
                        options: null,
                        data: null
                    }
                },
                iconCashAnimation: {
                    animationData: iconCashAnimationData.default,
                    loop: false
                },
            }
        },
        mounted() {
            this.$api.get('wallet/balances').then(response => {
                this.balances = response.data;
                this.fillBalanceChart();
                this.fillPartitionChart();
                if (this.balances.stats.commission_summary !== undefined) {
                    this.fillCommissionSummaryChart();
                }
            });

        },
        methods: {
            changeCommissionChartData(interval) {
                this.$api.get('wallet/commission-summary', {
                    interval: interval
                }).then(response => {
                    this.balances.stats.commission_summary = response.data.data;
                    this.fillCommissionSummaryChart();
                });
            },
            getStats(arr) {
                let data = [];
                arr.forEach(function (stat) {
                    data.push(stat.amount);
                });
                return data;
            },
            getLabels(arr) {
                let labels = [];
                arr.forEach(function (stat) {
                    labels.push(stat.date);
                });
                return labels;
            },
            fillBalanceChart() {
                this.chart.profit.data = {
                    labels: this.getLabels(this.balances.fiat.stats['30days']),
                    datasets: [
                        {
                            label: 'Balance Euro',
                            backgroundColor: '#bfc51d',
                            data: this.getStats(this.balances.fiat.stats['30days'])
                        }
                    ],
                };

                this.chart.profit.options = {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            type: 'time',
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 10,
                                maxRotation: 0,
                                minRotation: 0
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                callback: function (value) {
                                    return value + ' EUR';
                                },
                                autoSkip: true,
                                maxTicksLimit: 5
                            }
                        }]
                    }
                }
            },
            fillPartitionChart() {

                let labels = [];
                let data = [];

                this.balances.fiat.stats['30days_partition'].forEach((stats) => {
                    labels.push(this.$t('wallet.component.transactions.types.' + stats.type + '.title'));
                    data.push(stats.amount);
                });

                this.chart.partition.data = {
                    labels: labels,
                    datasets: [
                        {
                            label: 'Balance Euro',
                            backgroundColor: '#bfc51d',
                            data: data
                        }
                    ],
                };

                this.chart.partition.options = {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            },
            fillCommissionSummaryChart() {
                this.chart.commission_summary.data = {
                    labels: this.getLabels(this.balances.stats['commission_summary']),
                    datasets: [
                        {
                            label: 'Euro',
                            backgroundColor: '#bfc51d',
                            data: this.getStats(this.balances.stats['commission_summary'])
                        }
                    ],
                };

                this.chart.commission_summary.options = {
                    legend: {
                        display: false
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                }
            }
        }
    }
</script>
