<template>
    <div class="card card-data-browser card-default">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}

            <nav class="float-right">
                <ul class="pagination mb-0">
                    <li class="page-item"><span class="page-link" @click="prevPage"><i class="fad fa-arrow-left"></i></span></li>
                    <li class="page-item" :class="{active: page === state.page}" v-for="page in state.pages"><span class="page-link">{{page}}</span></li>
                    <li class="page-item"><span class="page-link" @click="nextPage"><i class="fad fa-arrow-right"></i></span></li>
                </ul>
            </nav>

        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">
            <div v-show="state.data.length" class="table-wrapper">
                <div class="table-responsive text-nowrap">
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>{{ $t('wallet.component.orders.label') }}</th>
                            <th>{{ $t('wallet.component.orders.amount') }}</th>
                            <th>{{ $t('wallet.component.orders.status') }}</th>
                            <th>{{ $t('wallet.component.orders.created_at') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in state.data">
                            <td>{{ item.label }}</td>
                            <td>{{ item.amount | currency }} EUR</td>
                            <td>{{ $t('wallet.component.orders.statuses.' + item.status) }}</td>
                            <td class="text-right">{{ $moment.utc(item.created_at).local().format('L') }} <span class="d-inline-block" style="width: 7em">{{ $moment.utc(item.created_at).local().format('LTS')}}</span></td>
                            <td class="p-0 pt-1 text-center">
                                <b-btn v-if="item.product.type === 'plant'" @click="downloadAgreement(item)" variant="link"><i class="fad fa-file-contract text-secondary" style="font-size: 1.3em"></i></b-btn>
                                <b-btn :to="{name: 'Wallet:Order', params: {order_label: item.label}}" variant="link"><i class="fad fa-eye text-secondary" style="font-size: 1.3em"></i></b-btn>
                                <b-btn @click="cancelOrder(item)" variant="link" v-if="item.cancelable"><i class="fad fa-trash-alt text-secondary text-danger" style="font-size: 1.3em"></i></b-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-primary">
                    {{ $t('wallet.component.orders.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
        extends: TableDataBrowser,
        data() {
            return {
                title: this.$t('wallet.component.orders.heading'),
                icon: 'fad fa-file-invoice-dollar',
                types: [],
                selectedType: undefined,
                endpoint: '/wallet/orders',
            }
        },
        methods: {
            init: function () {
                this.refresh();
            },
            setType: function () {
                if (this.selectedType === 'undefined') {
                    delete this.endpointParams.type;
                } else {
                    this.endpointParams.type = this.selectedType;
                }

                this.state.meta.current_page = 1;

                this.refresh();
            },
            downloadAgreement(order) {
                this.loading = true;
                this.$api.downloadPDF('wallet/orders/' + order.label + '/agreement', 'purchase-and-agency-agreement-' + order.label).then(() => {
                    this.loading = false;
                });
            },
            cancelOrder: function (order) {
                this.$swal.fire({
                    title: this.$t('wallet.component.orders.cancel.title'),
                    text: this.$t('wallet.component.orders.cancel.text', {order: order.label}),
                    showCancelButton: true
                }).then(response => {
                    if (response.value) {
                        this.$api.delete('wallet/orders/' + order.label).then(response => {
                            this.refresh();
                        })
                    }
                })
            }
        }
    }
</script>
