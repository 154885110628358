<template>
    <div class="card card-default card-stat">
        <div class="card-header">
            {{ $t('wallet.component.balance_cannabis.title') }}<i class="card-header-icon fad fa-boxes-alt"></i>
        </div>
        <div class="card-body py-0">
            <formatted-number :amount="balance ? balance.amount : 0" type="cannabis" animated=""/>
        </div>
        <div class="card-footer p-0">
            <b-btn v-if="$route.name !== 'Growing:Storage'" :to="{name: 'Growing:Storage'}" variant="link" v-b-tooltip.hover :title="$t('wallet.component.balance_cannabis.hover')"
                   class="float-right bg-white px-1 py-0 m-1"><i class="fad fa-sliders-h fa-2x"></i>
            </b-btn>
            <b-btn v-if="$route.name !== 'Growing:PlantBrowser'" :to="{name: 'Growing:PlantBrowser'}" variant="link" v-b-tooltip.hover :title="$t('common.layout.sidebar.nav.plants')"
                   class="float-right bg-white px-1 py-0 m-1"><i class="fad fa-seedling fa-2x"></i>
            </b-btn>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['balance']
    }
</script>