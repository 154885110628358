<template>
    <div class="card card-data-browser card-default">
        <div class="card-header">
            <i class="card-title-icon" :class="icon"></i> {{ title }}

            <nav class="float-right">
                <ul class="pagination mb-0">
                    <li class="page-item"><span class="page-link" @click="prevPage" href=""><i class="fad fa-arrow-left"></i></span></li>
                    <li class="page-item" :class="{active: page === state.page}" v-for="page in state.pages"><span class="page-link">{{page}}</span></li>
                    <li class="page-item"><span class="page-link" @click="nextPage" href=""><i class="fad fa-arrow-right"></i></span></li>
                </ul>
            </nav>

            <select class="form-control custom-select float-right wd-lg" v-model="selectedType" @change="setType()">
                <option value="undefined">{{ $t('wallet.component.transactions.all') }}</option>
                <option v-for="type in types" :value="type">{{ $t('wallet.component.transactions.types.' + type + '.title') }}</option>
            </select>

            <router-link v-if="state.data.length" :to="{name: 'Wallet:Summary'}" class="btn btn-xs btn-secondary float-right mr-3">{{ $t('wallet.component.transaction_browser.btn_export') }}</router-link>

        </div>
        <div class="card-body p-0" :class="loading ? 'whirl' : ''">
            <div v-show="state.data.length" class="table-wrapper">
                <div class="table-responsive text-nowrap">
                    <table class="table table-striped table-bordered">
                        <thead>
                        <tr>
                            <th>{{ $t('wallet.component.transactions.label') }}</th>
                            <th>{{ $t('wallet.component.transactions.type') }}</th>
                            <th>{{ $t('wallet.component.transactions.amount') }}</th>
                            <th>{{ $t('wallet.component.transactions.date') }}</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in state.data">
                            <td>{{ item.label }}</td>
                            <td>{{ $t('wallet.component.transactions.types.' + item.type + '.title') }}</td>

                            <td class="text-right">
                            <span class="text-danger" v-if="!item.positive">
                                -{{ item.amount | currency }} {{ item.currency.shorthand  }}
                            </span>
                                <span class="text-primary" v-if="item.positive">
                                {{ item.amount | currency }} {{ item.currency.shorthand  }}
                            </span>
                            </td>
                            <td class="text-right">{{ $moment.utc(item.created_at).local().format('L') }} <span class="d-inline-block" style="width: 7em">{{ $moment.utc(item.created_at).local().format('LTS')}}</span></td>
                            <td class="p-0 pt-1 text-center">
                                <b-btn :to="{name: 'Wallet:Transaction', params: {transaction_label: item.label}}" variant="link"><i class="fad fa-eye text-secondary" style="font-size:1.3em"></i>
                                </b-btn>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-show="!state.data.length && !loading">
                <div class="m-3 alert alert-info">
                    {{ $t('wallet.component.transactions.empty') }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import TableDataBrowser from '@/modules/Wallet/components/TableDataBrowser.vue';

    export default {
        extends: TableDataBrowser,
        props: ['preSelectType'],
        data() {
            return {
                title: this.$t('wallet.component.transactions.heading'),
                icon: 'fad fa-exchange',
                types: [],
                selectedType: undefined
            }
        },
        mounted() {
            this.refresh().then(response => {

                this.types = response.data.types;

                for (let type in this.types) {
                    if (type === this.preSelectType) {
                        this.selectedType = type;
                    }
                }

                this.setType();
            });
        },
        methods: {
            setType: function () {
                if (this.selectedType === 'undefined') {
                    delete this.endpointParams.type;
                } else {
                    this.endpointParams.type = this.selectedType;
                }

                this.state.meta.current_page = 1;

                this.refresh();
            }
        }
    }
</script>
