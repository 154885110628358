<template>
    <div class="card card-default card-stat">
        <div class="card-header">
            {{ $t('wallet.component.balance_fiat.title', {currency: balance ? balance.currency.label : ''}) }}
            <i class="card-header-icon cai-cash"></i>
        </div>
        <router-link :to="{name: 'Wallet'}" class="card-body" style="transition: none" :class="{'py-0': balance && balance.amount !== 0}">
            <formatted-number  :amount="balance ? balance.amount : 0" type="fiat" animated=""/>
        </router-link>
        <div class="card-footer p-0 text-center">
            <b-btn :to="{name: 'Wallet:ActionMakeDeposit'}" v-b-tooltip.hover
                   :title="$t('wallet.component.balance_fiat.button.deposit.hover')"
                   variant="link"
                   v-show="balance && balance.has_incoming_transactions && balance.amount !== 0"
                   class=" bg-white px-1 py-0 m-1 float-left"><i class="far fa-plus fa-2x"></i>
            </b-btn>

            <span v-if="balance && balance.locked"
                  class="m-auto d-inline-block pt-1"
                  v-b-tooltip.hover :title="$t('wallet.component.balance_fiat.locked_amount')">
                                <i class="fad fa-lock"></i> <formatted-number  :amount="balance.locked" type="fiat"/>
                            </span>

            <b-btn :to="{name: 'Wallet:ActionMakeWithdraw'}"
                   variant="link" v-b-tooltip.hover
                   :title="$t('wallet.component.balance_fiat.button.withdraw.hover')"
                   v-show="balance && balance.amount > 0"
                   class="float-right bg-white px-1 py-0 m-1"><i class="fad fa-minus fa-2x"></i>
            </b-btn>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['balance'],
    }
</script>